// 데이터를 JSON 파일로 다운로드하는 함수
export const downloadData = (data: any[], filename: string): void => {
    // JSON 문자열로 변환
    const jsonString = JSON.stringify(data, null, 2);

    // Blob 객체 생성
    const blob = new Blob([jsonString], { type: 'application/json' });

    // URL 생성
    const url = URL.createObjectURL(blob);

    // 임시 링크 요소 생성
    const link = document.createElement('a');
    link.href = url;
    link.download = `${filename}.json`;

    // 링크 클릭 (다운로드 시작)
    document.body.appendChild(link);
    link.click();

    // 링크 제거
    document.body.removeChild(link);

    // URL 해제
    URL.revokeObjectURL(url);
};

// CSV 형식으로 데이터를 다운로드하는 함수 (선택적)
export const downloadCSV = (data: any[], filename: string): void => {
    // CSV 문자열로 변환
    const headers = Object.keys(data[0]).join(',');
    const csv = data.map((row) => Object.values(row).join(',')).join('\n');
    const csvString = `${headers}\n${csv}`;

    // Blob 객체 생성
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });

    // URL 생성
    const url = URL.createObjectURL(blob);

    // 임시 링크 요소 생성
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${filename}.csv`);

    // 링크 클릭 (다운로드 시작)
    document.body.appendChild(link);
    link.click();

    // 링크 제거
    document.body.removeChild(link);

    // URL 해제
    URL.revokeObjectURL(url);
};
