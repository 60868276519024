import apiInstance from './base';

export const authApi = {
    login: async (loginData) => {
        const response = await apiInstance.post('/auth/login', loginData);
        return response.data;
    },

    logout: async () => {
        try {
            const response = await apiInstance.post('/auth/logout');
            return response.data;
        } catch (error) {
            throw error;
        }
    },
};
