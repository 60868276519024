import apiInstance from './base';

export const weatherApi = {
    // 기본 날씨 데이터 조회
    getWeatherData: () => apiInstance.get('/weather'),

    // 특정 날짜의 날씨 조회 (token override 가능)
    getWeatherByDate: (date, token) => apiInstance.get(`/weather/${date}`),

    // 날씨 데이터 필터링 조회
    getFilteredWeather: (params) => apiInstance.get('/weather/filter', { params }),
};
