import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Home, Thermometer, Droplets, Sprout, Menu, LogOut } from 'lucide-react';
import { removeCookie } from '../utils/cookies';
import { authApi } from '../api/login';

const Navigation: React.FC = () => {
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();

    const navItems = [
        { name: '대시보드', path: '/', icon: Home },
        { name: '온도 데이터', path: '/temperature', icon: Thermometer },
        { name: '습도 데이터', path: '/humidity', icon: Droplets },
        { name: '토양 데이터', path: '/soil', icon: Sprout },
    ];

    const handleLogout = async () => {
        try {
            await authApi.logout();
            // 서버 로그아웃이 성공하면 클라이언트 상태 정리
            removeCookie('user');
            localStorage.removeItem('isAuthenticated');
            navigate('/login', { replace: true });
        } catch (error) {
            console.error('로그아웃 중 에러 발생:', error);
            // 에러가 발생해도 클라이언트 상태는 정리
            removeCookie('user');
            localStorage.removeItem('isAuthenticated');
            navigate('/login', { replace: true });
        }
    };

    return (
        <>
            {/* Mobile Navigation */}
            <nav className="lg:hidden bg-white shadow-md">
                <div className="flex justify-between items-center px-4 py-3">
                    <h1 className="text-xl font-semibold">스마트팜 관리</h1>
                    <button onClick={() => setIsOpen(!isOpen)} className="text-gray-600">
                        <Menu size={24} />
                    </button>
                </div>
                {isOpen && (
                    <div className="px-2 pt-2 pb-3 space-y-1">
                        {navItems.map((item) => (
                            <NavLink
                                key={item.path}
                                to={item.path}
                                className={({ isActive }) =>
                                    `block px-3 py-2 rounded-md text-base font-medium ${
                                        isActive ? 'bg-gray-200 text-gray-900' : 'text-gray-600 hover:bg-gray-100'
                                    }`
                                }
                                onClick={() => setIsOpen(false)}
                            >
                                {item.name}
                            </NavLink>
                        ))}
                        <button
                            onClick={handleLogout}
                            className="block w-full text-left px-3 py-2 rounded-md text-base font-medium text-gray-600 hover:bg-gray-100"
                        >
                            로그아웃
                        </button>
                    </div>
                )}
            </nav>

            {/* Desktop Navigation */}
            <nav className="hidden lg:block bg-gray-800 w-64 min-h-screen">
                <div className="px-4 py-5">
                    <h1 className="text-2xl font-semibold text-white">스마트팜 관리</h1>
                </div>
                <ul className="mt-6">
                    {navItems.map((item) => (
                        <li key={item.path}>
                            <NavLink
                                to={item.path}
                                className={({ isActive }) =>
                                    `flex items-center px-6 py-3 text-gray-300 hover:bg-gray-700 hover:text-white transition-colors duration-300 ${
                                        isActive ? 'bg-gray-700 text-white' : ''
                                    }`
                                }
                            >
                                <item.icon className="w-5 h-5 mr-3" />
                                {item.name}
                            </NavLink>
                        </li>
                    ))}
                    <li>
                        <button
                            onClick={handleLogout}
                            className="flex items-center w-full px-6 py-3 text-gray-300 hover:bg-gray-700 hover:text-white transition-colors duration-300"
                        >
                            <LogOut className="w-5 h-5 mr-3" />
                            로그아웃
                        </button>
                    </li>
                </ul>
            </nav>
        </>
    );
};

export default Navigation;
