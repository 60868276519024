import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { Thermometer, Droplets, Activity, Zap, Cloud, Sprout, Power } from 'lucide-react';
import { weatherApi } from '../api/weather';
import { outDoorApi } from '../api/outdoor';
import { soilApi } from '../api/soil';

interface WeatherData {
    temperature: string;
    humidity: string;
    baseDate: string;
    baseTime: string;
}

interface SensorData {
    hour: string;
    temperature: string;
    humidity: string;
    ec: string;
    ph: string;
    n: string;
    p: string;
    k: string;
    power: string;
}

const Dashboard: React.FC = () => {
    const [outdoorSensorData, setOutdoorSensorData] = useState<SensorData[] | null>(null);
    const [soilData, setSoilData] = useState<SensorData[] | null>(null);
    const [meteorologicalData, setMeteorologicalData] = useState<WeatherData | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [isAutomationOn, setIsAutomationOn] = useState(false);
    const [remoteControlDuration, setRemoteControlDuration] = useState('');
    const [selectedMetric, setSelectedMetric] = useState<'temperature' | 'humidity' | 'ph' | 'ec'>('temperature');
    const [selectedOutdoorMetric, setSelectedOutdoorMetric] = useState<'temperature' | 'humidity'>('temperature');

    useEffect(() => {
        fetchWeatherData();
        fetchOutDoorData();
        fetchSoilData();
    }, []);

    const fetchWeatherData = async () => {
        try {
            const response = await weatherApi.getWeatherData();
            if (response.data.code !== 200) {
                throw new Error('날씨 데이터를 가져오는데 실패했습니다');
            }
            setMeteorologicalData(response.data.result);
            setIsLoading(false);
        } catch (err) {
            setError(err instanceof Error ? err.message : '알 수 없는 오류가 발생했습니다');
            setIsLoading(false);
        }
    };

    const fetchOutDoorData = async () => {
        try {
            const response = await outDoorApi.getoutDoorData();
            if (response.data.code !== 200) {
                throw new Error('외부 데이터를 가져오는데 실패했습니다');
            }
            setOutdoorSensorData(response.data.result);
            setIsLoading(false);
        } catch (err) {
            setError(err instanceof Error ? err.message : '알 수 없는 오류가 발생했습니다');
            setIsLoading(false);
        }
    };

    const fetchSoilData = async () => {
        try {
            const response = await soilApi.getSoilData();
            if (response.data.code !== 200) {
                throw new Error('외부 데이터를 가져오는데 실패했습니다');
            }
            setSoilData(response.data.result);
            setIsLoading(false);
        } catch (err) {
            setError(err instanceof Error ? err.message : '알 수 없는 오류가 발생했습니다');
            setIsLoading(false);
        }
    };

    const DataSection: React.FC<{ title: string; icon: React.ReactNode; children: React.ReactNode }> = ({
        title,
        icon,
        children,
    }) => (
        <div className="bg-white rounded-lg shadow-md p-6 mb-6">
            <div className="flex items-center mb-4">
                <div className="bg-indigo-100 rounded-full p-2 mr-3">{icon}</div>
                <h2 className="text-xl font-semibold text-gray-800">{title}</h2>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">{children}</div>
        </div>
    );

    const DataCard: React.FC<{ title: string; value: string | number; unit: string; icon: React.ReactNode }> = ({
        title,
        value,
        unit,
        icon,
    }) => (
        <div className="bg-gray-50 rounded-lg p-4 flex items-center">
            <div className="bg-indigo-100 rounded-full p-2 mr-4">{icon}</div>
            <div>
                <p className="text-sm font-medium text-gray-500">{title}</p>
                <p className="text-2xl font-bold text-gray-800 mt-1">
                    {value}
                    <span className="text-lg ml-1">{unit}</span>
                </p>
            </div>
        </div>
    );

    const handleAutomationToggle = () => {
        setIsAutomationOn(!isAutomationOn);
    };

    const handleRemoteControl = (e: React.FormEvent) => {
        e.preventDefault();
        console.log(`시스템을 ${remoteControlDuration}분 동안 가동합니다.`);
        setRemoteControlDuration('');
    };

    if (isLoading) {
        return <div className="p-4">데이터를 불러오는 중...</div>;
    }

    if (error) {
        return <div className="p-4 text-red-500">Error: {error}</div>;
    }

    return (
        <div className="p-4">
            <h1 className="text-3xl font-bold text-gray-800 mb-6">스마트팜 대시보드</h1>

            <DataSection title="외부 기상대 데이터" icon={<Cloud className="w-6 h-6 text-indigo-600" />}>
                {outdoorSensorData && outdoorSensorData.length > 0 && (
                    <>
                        <DataCard
                            title="온도"
                            value={parseFloat(outdoorSensorData[outdoorSensorData.length - 1].temperature).toFixed(1)}
                            unit="°C"
                            icon={<Thermometer className="w-5 h-5 text-indigo-600" />}
                        />
                        <DataCard
                            title="습도"
                            value={parseFloat(outdoorSensorData[outdoorSensorData.length - 1].humidity).toFixed(1)}
                            unit="%"
                            icon={<Droplets className="w-5 h-5 text-indigo-600" />}
                        />
                        <div className="col-span-full text-sm text-gray-500">
                            마지막 업데이트: {outdoorSensorData[outdoorSensorData.length - 1].hour}
                        </div>
                    </>
                )}
            </DataSection>

            <DataSection title="토양 데이터" icon={<Sprout className="w-6 h-6 text-indigo-600" />}>
                {soilData && soilData.length > 0 && (
                    <>
                        <DataCard
                            title="온도"
                            value={parseFloat(soilData[soilData.length - 1].temperature).toFixed(1)}
                            unit="°C"
                            icon={<Thermometer className="w-5 h-5 text-indigo-600" />}
                        />
                        <DataCard
                            title="습도"
                            value={parseFloat(soilData[soilData.length - 1].humidity).toFixed(1)}
                            unit="%"
                            icon={<Droplets className="w-5 h-5 text-indigo-600" />}
                        />
                        <DataCard
                            title="pH"
                            value={parseFloat(soilData[soilData.length - 1].ph).toFixed(1)}
                            unit=""
                            icon={<Activity className="w-5 h-5 text-indigo-600" />}
                        />
                        <DataCard
                            title="EC"
                            value={parseFloat(soilData[soilData.length - 1].ec).toFixed(2)}
                            unit="mS/cm"
                            icon={<Zap className="w-5 h-5 text-indigo-600" />}
                        />
                        <div className="col-span-full text-sm text-gray-500">
                            마지막 업데이트: {soilData[soilData.length - 1].hour}
                        </div>
                    </>
                )}
            </DataSection>

            <DataSection title="기상청 데이터" icon={<Cloud className="w-6 h-6 text-indigo-600" />}>
                {meteorologicalData && (
                    <>
                        <DataCard
                            title="온도"
                            value={parseFloat(meteorologicalData.temperature).toFixed(1)}
                            unit="°C"
                            icon={<Thermometer className="w-5 h-5 text-indigo-600" />}
                        />
                        <DataCard
                            title="습도"
                            value={parseFloat(meteorologicalData.humidity).toFixed(1)}
                            unit="%"
                            icon={<Droplets className="w-5 h-5 text-indigo-600" />}
                        />
                        <div className="col-span-full text-sm text-gray-500">
                            마지막 업데이트: {meteorologicalData.baseDate.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3')}{' '}
                            {meteorologicalData.baseTime.replace(/(\d{2})(\d{2})/, '$1:$2')}
                        </div>
                    </>
                )}
            </DataSection>

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mt-6">
                <div className="bg-white rounded-lg shadow-md p-4 sm:p-6">
                    <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 gap-3">
                        {' '}
                        {/* flex 방향 및 간격 조정 */}
                        <div className="flex items-center">
                            <div className="bg-indigo-100 rounded-full p-2 mr-3">
                                <Cloud className="w-6 h-6 text-indigo-600" />
                            </div>
                            <h2 className="text-lg sm:text-xl font-semibold text-gray-800">외부 기상대 데이터 추이</h2>
                        </div>
                        <div className="flex flex-wrap gap-2 w-full sm:w-auto">
                            {' '}
                            {/* 버튼 래퍼 수정 */}
                            <button
                                onClick={() => setSelectedOutdoorMetric('temperature')}
                                className={`flex-1 sm:flex-none px-3 py-1 rounded-full text-sm ${
                                    selectedOutdoorMetric === 'temperature'
                                        ? 'bg-indigo-100 text-indigo-600'
                                        : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                                }`}
                            >
                                <Thermometer className="w-4 h-4 inline-block mr-1" />
                                온도
                            </button>
                            <button
                                onClick={() => setSelectedOutdoorMetric('humidity')}
                                className={`flex-1 sm:flex-none px-3 py-1 rounded-full text-sm ${
                                    selectedOutdoorMetric === 'humidity'
                                        ? 'bg-indigo-100 text-indigo-600'
                                        : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                                }`}
                            >
                                <Droplets className="w-4 h-4 inline-block mr-1" />
                                습도
                            </button>
                        </div>
                    </div>
                    <div className="h-[300px] w-full">
                        {' '}
                        {/* 차트 컨테이너 크기 고정 */}
                        <ResponsiveContainer width="100%" height={300}>
                            <LineChart data={outdoorSensorData || []}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis
                                    dataKey="hour"
                                    interval="preserveStartEnd"
                                    minTickGap={50}
                                    tickFormatter={(value) => {
                                        const date = new Date(value);
                                        if (date.getMinutes() === 0) {
                                            return `${String(date.getHours()).padStart(2, '0')}:00`;
                                        }
                                        return '';
                                    }}
                                />
                                <YAxis
                                    domain={selectedOutdoorMetric === 'temperature' ? [-20, 50] : [0, 100]}
                                    label={{
                                        angle: -90,
                                        position: 'insideLeft',
                                        style: { textAnchor: 'middle' },
                                    }}
                                />
                                <Tooltip
                                    labelFormatter={(label) => {
                                        return new Date(label).toLocaleString('ko-KR', {
                                            month: 'short',
                                            day: 'numeric',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                        });
                                    }}
                                    formatter={(value: any) => [Number(value).toFixed(1)]}
                                />
                                {selectedOutdoorMetric === 'temperature' && (
                                    <Line
                                        type="monotone"
                                        dataKey="temperature"
                                        stroke="#FF4B4B"
                                        name="온도 (°C)"
                                        dot={false}
                                        activeDot={{ r: 4 }}
                                    />
                                )}
                                {selectedOutdoorMetric === 'humidity' && (
                                    <Line
                                        type="monotone"
                                        dataKey="humidity"
                                        stroke="#82ca9d"
                                        name="습도 (%)"
                                        dot={false}
                                        activeDot={{ r: 4 }}
                                    />
                                )}
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                </div>

                <div className="bg-white rounded-lg shadow-md p-4 sm:p-6">
                    <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 gap-3">
                        <div className="flex items-center">
                            <div className="bg-indigo-100 rounded-full p-2 mr-3">
                                <Sprout className="w-6 h-6 text-indigo-600" />
                            </div>
                            <h2 className="text-lg sm:text-xl font-semibold text-gray-800">토양 데이터 추이</h2>
                        </div>
                        <div className="flex flex-wrap gap-2 w-full sm:w-auto">
                            {[
                                { type: 'temperature', label: '온도', icon: <Thermometer className="w-4 h-4" /> },
                                { type: 'humidity', label: '습도', icon: <Droplets className="w-4 h-4" /> },
                                { type: 'ph', label: 'pH', icon: <Activity className="w-4 h-4" /> },
                                { type: 'ec', label: 'EC', icon: <Zap className="w-4 h-4" /> },
                            ].map((item) => (
                                <button
                                    key={item.type}
                                    onClick={() =>
                                        setSelectedMetric(item.type as 'temperature' | 'humidity' | 'ph' | 'ec')
                                    }
                                    className={`flex items-center justify-center min-w-[80px] px-3 py-1 rounded-full text-sm ${
                                        selectedMetric === item.type
                                            ? 'bg-indigo-100 text-indigo-600'
                                            : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                                    }`}
                                >
                                    <div className="flex items-center">
                                        {item.icon}
                                        <span className="ml-1 inline-block">{item.label}</span>
                                    </div>
                                </button>
                            ))}
                        </div>
                    </div>
                    <div className="h-[300px] w-full">
                        <ResponsiveContainer width="100%" height={300}>
                            <LineChart data={soilData || []}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis
                                    dataKey="hour"
                                    interval="preserveStartEnd"
                                    minTickGap={50}
                                    tickFormatter={(value) => {
                                        const date = new Date(value);
                                        if (date.getMinutes() === 0) {
                                            return `${String(date.getHours()).padStart(2, '0')}:00`;
                                        }
                                        return '';
                                    }}
                                />
                                <YAxis
                                    domain={
                                        selectedMetric === 'temperature'
                                            ? [-20, 50]
                                            : selectedMetric === 'humidity'
                                            ? [0, 100]
                                            : selectedMetric === 'ph'
                                            ? [0, 8]
                                            : [0, 150]
                                    }
                                    label={{
                                        angle: -90,
                                        position: 'insideLeft',
                                        style: { textAnchor: 'middle' },
                                    }}
                                />
                                <Tooltip
                                    labelFormatter={(label) => {
                                        return new Date(label).toLocaleString('ko-KR', {
                                            month: 'short',
                                            day: 'numeric',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                        });
                                    }}
                                    formatter={(value: any) => [Number(value).toFixed(1)]}
                                />
                                {selectedMetric === 'temperature' && (
                                    <Line
                                        type="monotone"
                                        dataKey="temperature"
                                        stroke="#FF4B4B"
                                        name="온도 (°C)"
                                        dot={false}
                                        activeDot={{ r: 4 }}
                                    />
                                )}
                                {selectedMetric === 'humidity' && (
                                    <Line
                                        type="monotone"
                                        dataKey="humidity"
                                        stroke="#82ca9d"
                                        name="습도 (%)"
                                        dot={false}
                                        activeDot={{ r: 4 }}
                                    />
                                )}
                                {selectedMetric === 'ph' && (
                                    <Line
                                        type="monotone"
                                        dataKey="ph"
                                        stroke="#ffa500"
                                        name="pH"
                                        dot={false}
                                        activeDot={{ r: 4 }}
                                    />
                                )}
                                {selectedMetric === 'ec' && (
                                    <Line
                                        type="monotone"
                                        dataKey="ec"
                                        stroke="#ff4500"
                                        name="EC (mS/cm)"
                                        dot={false}
                                        activeDot={{ r: 4 }}
                                    />
                                )}
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                </div>
                <div className="bg-white rounded-lg shadow-md p-6 mb-8">
                    <h2 className="text-xl font-bold text-gray-800 mb-4">자동화 시스템</h2>
                    <div className="flex flex-col md:flex-row justify-between items-start md:items-center">
                        <div className="mb-4 md:mb-0">
                            <h3 className="text-lg font-semibold mb-2">시스템 상태</h3>
                            <button
                                onClick={handleAutomationToggle}
                                className={`px-4 py-2 rounded-md text-white font-medium ${
                                    isAutomationOn ? 'bg-green-500 hover:bg-green-600' : 'bg-red-500 hover:bg-red-600'
                                }`}
                            >
                                <Power className="w-4 h-4 inline-block mr-2" />
                                {isAutomationOn ? 'ON' : 'OFF'}
                            </button>
                        </div>
                        <div>
                            <h3 className="text-lg font-semibold mb-2">원격 제어</h3>
                            <form onSubmit={handleRemoteControl} className="flex items-center">
                                <input
                                    type="number"
                                    value={remoteControlDuration}
                                    onChange={(e) => setRemoteControlDuration(e.target.value)}
                                    placeholder="가동 시간 (분)"
                                    className="border border-gray-300 rounded-md px-3 py-2 mr-2"
                                />
                                <button
                                    type="submit"
                                    className="bg-blue-500 hover:bg-blue-600 text-white font-medium px-4 py-2 rounded-md"
                                >
                                    적용
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
