import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Thermometer, Download } from 'lucide-react';
import { downloadData } from '../utils/downloadData';
import { weatherApi } from '../api/weather';
import { outDoorApi } from '../api/outdoor';

type SourceType = 'all' | 'external' | 'meteorological';

interface WeatherData {
    temperature: string;
    humidity: string;
    baseDate: string;
    baseTime: string;
}

interface SensorData {
    hour: string;
    temperature: string;
    humidity: string;
    ec: string;
    ph: string;
    n: string;
    p: string;
    k: string;
    power: string;
}

const TemperatureData: React.FC = () => {
    const [selectedSource, setSelectedSource] = useState<SourceType>('all');
    const [outdoorSensorData, setOutdoorSensorData] = useState<SensorData[] | null>(null);
    const [meteorologicalData, setMeteorologicalData] = useState<WeatherData | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        fetchWeatherData();
        fetchOutDoorData();
    }, []);

    const fetchWeatherData = async () => {
        try {
            const response = await weatherApi.getWeatherData();
            if (response.data.code !== 200) {
                throw new Error('날씨 데이터를 가져오는데 실패했습니다');
            }
            setMeteorologicalData(response.data.result);
            setIsLoading(false);
        } catch (err) {
            setError(err instanceof Error ? err.message : '알 수 없는 오류가 발생했습니다');
            setIsLoading(false);
        }
    };

    const fetchOutDoorData = async () => {
        try {
            const response = await outDoorApi.getoutDoorData();
            if (response.data.code !== 200) {
                throw new Error('외부 데이터를 가져오는데 실패했습니다');
            }
            setOutdoorSensorData(response.data.result);
            setIsLoading(false);
        } catch (err) {
            setError(err instanceof Error ? err.message : '알 수 없는 오류가 발생했습니다');
            setIsLoading(false);
        }
    };

    const combinedData = outdoorSensorData?.map((item) => ({
        time: item.hour,
        external: parseFloat(item.temperature),
        meteorological: meteorologicalData ? parseFloat(meteorologicalData.temperature) : null,
    }));

    const DataCard: React.FC<{ title: string; value: string | number; unit: string; source: string }> = ({
        title,
        value,
        unit,
        source,
    }) => (
        <div className="bg-white rounded-lg shadow-md p-6">
            <div className="flex items-center justify-between">
                <div className="flex items-center">
                    <Thermometer className="w-8 h-8 text-blue-500 mr-3" />
                    <div>
                        <p className="text-sm font-medium text-gray-500">{title}</p>
                        <p className="text-2xl font-bold">
                            {typeof value === 'string' ? parseFloat(value).toFixed(1) : value.toFixed(1)}
                            {unit}
                        </p>
                    </div>
                </div>
                <span className="text-sm font-medium text-gray-500">{source}</span>
            </div>
        </div>
    );

    if (isLoading) {
        return <div className="p-4">데이터를 불러오는 중...</div>;
    }

    if (error) {
        return <div className="p-4 text-red-500">Error: {error}</div>;
    }

    return (
        <div className="p-6 bg-gray-100 min-h-screen">
            <div className="max-w-7xl mx-auto">
                <div className="flex justify-between items-center mb-6">
                    <h1 className="text-3xl font-bold text-gray-800">온도 데이터</h1>
                    <button
                        onClick={() => downloadData(combinedData || [], 'temperature_data')}
                        className="flex items-center px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
                    >
                        <Download className="w-5 h-5 mr-2" />
                        데이터 다운로드
                    </button>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
                    {outdoorSensorData && outdoorSensorData.length > 0 && (
                        <DataCard
                            title="외부 온도"
                            value={outdoorSensorData[outdoorSensorData.length - 1].temperature}
                            unit="°C"
                            source="외부 기상대"
                        />
                    )}
                    {meteorologicalData && (
                        <DataCard
                            title="기상청 온도"
                            value={meteorologicalData.temperature}
                            unit="°C"
                            source="기상청"
                        />
                    )}
                </div>

                <div className="bg-white rounded-lg shadow-md p-4 sm:p-6 mb-8">
                    <h2 className="text-xl font-bold mb-4">온도 추이</h2>
                    <div className="h-[300px] sm:h-[400px] w-full">
                        {' '}
                        {/* 모바일에서는 높이를 줄임 */}
                        <ResponsiveContainer>
                            <LineChart
                                data={combinedData || []}
                                margin={{
                                    // 마진 조정으로 잘리는 부분 방지
                                    top: 5,
                                    right: 10,
                                    left: 0,
                                    bottom: 5,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis
                                    dataKey="time"
                                    interval="preserveStartEnd"
                                    minTickGap={50}
                                    tickFormatter={(value) => {
                                        const date = new Date(value);
                                        if (date.getMinutes() === 0) {
                                            return `${String(date.getHours()).padStart(2, '0')}:00`;
                                        }
                                        return '';
                                    }}
                                    height={40} // X축 높이 조정
                                    tick={{
                                        // 텍스트 스타일 조정
                                        fontSize: 12,
                                        fontFamily: 'system-ui',
                                    }}
                                />
                                <YAxis
                                    domain={[-20, 50]}
                                    width={45} // Y축 너비 조정
                                    tick={{
                                        // 텍스트 스타일 조정
                                        fontSize: 12,
                                        fontFamily: 'system-ui',
                                    }}
                                />
                                <Tooltip
                                    labelFormatter={(label) => {
                                        return new Date(label).toLocaleString('ko-KR', {
                                            month: 'short',
                                            day: 'numeric',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                        });
                                    }}
                                    formatter={(value: any) => [Number(value).toFixed(1)]}
                                    contentStyle={{
                                        backgroundColor: 'rgba(255, 255, 255, 0.95)',
                                        borderRadius: '8px',
                                        padding: '8px',
                                        fontSize: '12px',
                                        border: '1px solid #e2e8f0',
                                    }}
                                />
                                <Legend
                                    height={36}
                                    iconSize={8} // 범례 아이콘 크기 조정
                                    wrapperStyle={{
                                        // 범례 스타일 조정
                                        fontSize: '12px',
                                        paddingTop: '8px',
                                    }}
                                />
                                <Line
                                    type="monotone"
                                    dataKey="external"
                                    stroke="#8884d8"
                                    name="외부 온도 (°C)"
                                    dot={false}
                                    activeDot={{ r: 4 }}
                                    strokeWidth={2} // 선 굵기 조정
                                />
                                <Line
                                    type="monotone"
                                    dataKey="meteorological"
                                    stroke="#ffa500"
                                    name="기상청 온도 (°C)"
                                    dot={false}
                                    activeDot={{ r: 4 }}
                                    strokeWidth={2} // 선 굵기 조정
                                />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                </div>

                <div className="bg-white rounded-lg shadow-md overflow-hidden">
                    <div className="p-4 bg-gray-50 border-b">
                        <select
                            value={selectedSource}
                            onChange={(e) => setSelectedSource(e.target.value as SourceType)}
                            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                        >
                            <option value="all">모든 데이터</option>
                            <option value="external">외부 기상대</option>
                            <option value="meteorological">기상청</option>
                        </select>
                    </div>
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                            <tr>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    시간
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    온도 (°C)
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    데이터 출처
                                </th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {combinedData?.flatMap((data, index) => {
                                const rows: JSX.Element[] = [];
                                if (selectedSource === 'all' || selectedSource === 'external') {
                                    rows.push(
                                        <tr key={`external-${index}`}>
                                            <td className="px-6 py-4 whitespace-nowrap">{data.time}</td>
                                            <td className="px-6 py-4 whitespace-nowrap">{data.external.toFixed(1)}</td>
                                            <td className="px-6 py-4 whitespace-nowrap">외부 기상대</td>
                                        </tr>
                                    );
                                }
                                if (selectedSource === 'all' || selectedSource === 'meteorological') {
                                    rows.push(
                                        <tr key={`meteorological-${index}`}>
                                            <td className="px-6 py-4 whitespace-nowrap">{data.time}</td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                {data.meteorological?.toFixed(1) || '-'}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">기상청</td>
                                        </tr>
                                    );
                                }
                                return rows;
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default TemperatureData;
