import axios from 'axios';

const baseURL = process.env.REACT_APP_API_URL || 'http://localhost:5001';

// 기본 Axios 인스턴스 생성
const apiInstance = axios.create({
    baseURL,
    withCredentials: true,
});

// 인증 에러 처리 함수
const handleAuthError = () => {
    localStorage.removeItem('isAuthenticated');
    window.location.href = '/login';
};

// 요청 인터셉터
apiInstance.interceptors.request.use(
    (config) => {
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// 응답 인터셉터
apiInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        // 인증 관련 에러 처리 (401, 403)
        if (error.response && [401, 403].includes(error.response.status)) {
            handleAuthError();
        }
        return Promise.reject(error);
    }
);

export default apiInstance;
