import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, Outlet } from 'react-router-dom';
import Navigation from './components/Navigation';
import Dashboard from './pages/Dashboard';
import TemperatureData from './pages/TemperatureData';
import HumidityData from './pages/HumidityData';
import SoilData from './pages/SoilData';
import LoginPage from './pages/LoginPage';

// 레이아웃 컴포넌트
const Layout = () => {
    return (
        <div className="flex flex-col lg:flex-row min-h-screen bg-gray-100">
            <Navigation />
            <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100">
                <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8">
                    <Outlet />
                </div>
            </main>
        </div>
    );
};

// 보호된 라우트 컴포넌트
const ProtectedRoute = () => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const authStatus = localStorage.getItem('isAuthenticated');
        if (authStatus === 'true') {
            setIsAuthenticated(true);
        } else {
            setIsAuthenticated(false);
        }
        setLoading(false);
    }, []);

    if (loading) {
        return <div>로딩 중입니다...</div>;
    }

    if (!isAuthenticated) {
        return <Navigate to="/login" replace />;
    }

    return <Layout />;
};

const App: React.FC = () => {
    return (
        <Router>
            <Routes>
                {/* 로그인 페이지 */}
                <Route
                    path="/login"
                    element={
                        localStorage.getItem('isAuthenticated') === 'true' ? <Navigate to="/" replace /> : <LoginPage />
                    }
                />

                {/* 보호된 라우트들 - Layout을 통해 렌더링 */}
                <Route element={<ProtectedRoute />}>
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/temperature" element={<TemperatureData />} />
                    <Route path="/humidity" element={<HumidityData />} />
                    <Route path="/soil" element={<SoilData />} />
                </Route>

                {/* 기본 리다이렉트 */}
                <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
        </Router>
    );
};

export default App;
