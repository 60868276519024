import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { Thermometer, Droplets, Activity, Zap, Download } from 'lucide-react';
import { downloadData } from '../utils/downloadData';
import { soilApi } from '../api/soil';

type DataType = 'temperature' | 'humidity' | 'ph' | 'ec';

interface SensorData {
    hour: string;
    temperature: string;
    humidity: string;
    ec: string;
    ph: string;
    n: string;
    p: string;
    k: string;
    power: string;
}

const SoilData: React.FC = () => {
    const [selectedDataType, setSelectedDataType] = useState<DataType>('temperature');
    const [soilData, setSoilData] = useState<SensorData[] | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        fetchSoilData();
    }, []);

    const fetchSoilData = async () => {
        try {
            const response = await soilApi.getSoilData();
            if (response.data.code !== 200) {
                throw new Error('토양 데이터를 가져오는데 실패했습니다');
            }
            setSoilData(response.data.result);
            setIsLoading(false);
        } catch (err) {
            setError(err instanceof Error ? err.message : '알 수 없는 오류가 발생했습니다');
            setIsLoading(false);
        }
    };

    const DataCard: React.FC<{ title: string; value: string | number; unit: string; icon: React.ReactNode }> = ({
        title,
        value,
        unit,
        icon,
    }) => (
        <div className="bg-white rounded-lg shadow-md p-6">
            <div className="flex items-center justify-between">
                <div className="flex items-center">
                    {icon}
                    <div className="ml-3">
                        <p className="text-sm font-medium text-gray-500">{title}</p>
                        <p className="text-2xl font-bold">
                            {typeof value === 'string' ? parseFloat(value).toFixed(2) : value.toFixed(2)}
                            {unit}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );

    const getDataByType = (data: SensorData, type: DataType): string => {
        switch (type) {
            case 'temperature':
                return data.temperature;
            case 'humidity':
                return data.humidity;
            case 'ph':
                return data.ph;
            case 'ec':
                return data.ec;
        }
    };

    const getUnitByType = (type: DataType) => {
        switch (type) {
            case 'temperature':
                return '°C';
            case 'humidity':
                return '%';
            case 'ph':
                return '';
            case 'ec':
                return 'mS/cm';
        }
    };

    const dataTypes: { type: DataType; label: string; icon: React.ReactNode; color: string }[] = [
        { type: 'temperature', label: '온도', icon: <Thermometer className="w-4 h-4" />, color: 'text-red-500' },
        { type: 'humidity', label: '습도', icon: <Droplets className="w-4 h-4" />, color: 'text-blue-500' },
        { type: 'ph', label: 'pH', icon: <Activity className="w-4 h-4" />, color: 'text-green-500' },
        { type: 'ec', label: 'EC', icon: <Zap className="w-4 h-4" />, color: 'text-purple-500' },
    ];

    if (isLoading) {
        return <div className="p-4">데이터를 불러오는 중...</div>;
    }

    if (error) {
        return <div className="p-4 text-red-500">Error: {error}</div>;
    }

    return (
        <div className="p-6 bg-gray-100 min-h-screen">
            <div className="max-w-7xl mx-auto">
                <div className="flex justify-between items-center mb-6">
                    <h1 className="text-3xl font-bold text-gray-800">토양 데이터</h1>
                    <button
                        onClick={() => downloadData(soilData || [], 'soil_data')}
                        className="flex items-center px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
                    >
                        <Download className="w-5 h-5 mr-2" />
                        데이터 다운로드
                    </button>
                </div>

                {soilData && soilData.length > 0 && (
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
                        <DataCard
                            title="토양 온도"
                            value={soilData[soilData.length - 1].temperature}
                            unit="°C"
                            icon={<Thermometer className="w-8 h-8 text-red-500" />}
                        />
                        <DataCard
                            title="토양 습도"
                            value={soilData[soilData.length - 1].humidity}
                            unit="%"
                            icon={<Droplets className="w-8 h-8 text-blue-500" />}
                        />
                        <DataCard
                            title="토양 pH"
                            value={soilData[soilData.length - 1].ph}
                            unit=""
                            icon={<Activity className="w-8 h-8 text-green-500" />}
                        />
                        <DataCard
                            title="토양 EC"
                            value={soilData[soilData.length - 1].ec}
                            unit="mS/cm"
                            icon={<Zap className="w-8 h-8 text-purple-500" />}
                        />
                    </div>
                )}

                <div className="bg-white rounded-lg shadow-md p-4 sm:p-6 mb-8">
                    <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 gap-3">
                        <h2 className="text-xl font-bold mb-2 sm:mb-0">토양 데이터 추이</h2>
                        <div className="flex flex-wrap gap-2 w-full sm:w-auto">
                            {dataTypes.map(({ type, label, icon, color }) => (
                                <button
                                    key={type}
                                    onClick={() => setSelectedDataType(type)}
                                    className={`flex items-center justify-center min-w-[80px] px-3 py-1 rounded-full text-sm ${
                                        selectedDataType === type
                                            ? `bg-${color.split('-')[1]}-100 ${color}`
                                            : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                                    }`}
                                >
                                    <div className="flex items-center">
                                        {icon}
                                        <span className="ml-1">{label}</span>
                                    </div>
                                </button>
                            ))}
                        </div>
                    </div>
                    <div className="h-[300px] sm:h-[400px] w-full">
                        <ResponsiveContainer>
                            <LineChart
                                data={soilData || []}
                                margin={{
                                    top: 5,
                                    right: 10,
                                    left: 0,
                                    bottom: 5,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis
                                    dataKey="hour"
                                    interval="preserveStartEnd"
                                    minTickGap={50}
                                    tickFormatter={(value) => {
                                        const date = new Date(value);
                                        if (date.getMinutes() === 0) {
                                            return `${String(date.getHours()).padStart(2, '0')}:00`;
                                        }
                                        return '';
                                    }}
                                    height={40}
                                    tick={{
                                        fontSize: 12,
                                        fontFamily: 'system-ui',
                                    }}
                                />
                                <YAxis
                                    domain={
                                        selectedDataType === 'temperature'
                                            ? [-20, 50]
                                            : selectedDataType === 'humidity'
                                            ? [0, 100]
                                            : selectedDataType === 'ph'
                                            ? [0, 14]
                                            : [0, 200]
                                    }
                                    width={45}
                                    tick={{
                                        fontSize: 12,
                                        fontFamily: 'system-ui',
                                    }}
                                />
                                <Tooltip
                                    labelFormatter={(label) => {
                                        return new Date(label).toLocaleString('ko-KR', {
                                            month: 'short',
                                            day: 'numeric',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                        });
                                    }}
                                    formatter={(value: any) => [Number(value).toFixed(2)]}
                                    contentStyle={{
                                        backgroundColor: 'rgba(255, 255, 255, 0.95)',
                                        borderRadius: '8px',
                                        padding: '8px',
                                        fontSize: '12px',
                                        border: '1px solid #e2e8f0',
                                    }}
                                />
                                <Line
                                    type="monotone"
                                    dataKey={selectedDataType}
                                    stroke={
                                        dataTypes.find((d) => d.type === selectedDataType)?.color.split('-')[1] ||
                                        '#8884d8'
                                    }
                                    name={`${
                                        dataTypes.find((d) => d.type === selectedDataType)?.label
                                    } (${getUnitByType(selectedDataType)})`}
                                    dot={false}
                                    activeDot={{ r: 4 }}
                                    strokeWidth={2}
                                />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                </div>

                <div className="bg-white rounded-lg shadow-md overflow-hidden">
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                            <tr>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    시간
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    온도 (°C)
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    습도 (%)
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    pH
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    EC (mS/cm)
                                </th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {soilData?.map((data, index) => (
                                <tr key={index}>
                                    <td className="px-6 py-4 whitespace-nowrap">{data.hour}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        {parseFloat(data.temperature).toFixed(2)}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        {parseFloat(data.humidity).toFixed(2)}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">{parseFloat(data.ph).toFixed(2)}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">{parseFloat(data.ec).toFixed(2)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default SoilData;
